<script setup lang="ts">

useHead({
  titleTemplate: titleChunk => titleChunk || 'Blog Yescapa',
})
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 250ms;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
}
</style>
