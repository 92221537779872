import { RequestCreateNavigationEvent } from '@yescapa-dev/ysc-api-js/modern'
import type { APINavigationEvent } from '@yescapa-dev/ysc-api-js/modern'
import type { LocationQueryValue } from '#vue-router'

export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server) {
    return
  }

  const eventPayload: APINavigationEvent = {
    path: to.path,
    event_type: 'page_viewed',
    created_at: new Date().toISOString(),
    event_properties: {
      category: 'General',
    },
  }

  const setEventPropertiesIfDefined = (evt: APINavigationEvent, key: keyof APINavigationEvent['event_properties'], val: LocationQueryValue | LocationQueryValue[]) => {
    const v = queryValueToString(val)
    if (v && key !== 'category') {
      evt.event_properties[key] = v
    }
  }

  const { query: { utm_campaign, utm_content, utm_medium, utm_source, utm_term } } = from

  setEventPropertiesIfDefined(eventPayload, 'utm_campaign', utm_campaign)
  setEventPropertiesIfDefined(eventPayload, 'utm_content', utm_content)
  setEventPropertiesIfDefined(eventPayload, 'utm_medium', utm_medium)
  setEventPropertiesIfDefined(eventPayload, 'utm_source', utm_source)
  setEventPropertiesIfDefined(eventPayload, 'utm_term', utm_term)

  const { $apiConsumer } = useNuxtApp()
  try {
    // Don't await the request as we don't want it to be blocking
    $apiConsumer.send(new RequestCreateNavigationEvent({ payload: [eventPayload] }))
  } catch (e) {
    // Don't report on sentry as it could lead to an unwanted extra cost
    console.error(e)
  }
})
