import type { ErrorEvent, EventHint } from '@sentry/types'

export function sentryBeforeSend (event:ErrorEvent, hint: EventHint) {
  if (hint.originalException instanceof Error && 'fingerprint' in hint.originalException && typeof hint.originalException.fingerprint === 'string') {
    event.fingerprint = [hint.originalException.fingerprint]
  }

  if (event.exception) {
    console.error(`[Exeption handled by Sentry]: (${hint.originalException})`, { event, hint })
  }

  if (event.message) {
    console.info(`[Message handled by Sentry]: (${event.message})`, event.extra)
  }

  return event
}
