import type { GeneralSettings } from '~~/types/api/settings'

interface MenuModel {
  computed_url: string
  computed_title: string
  sub_menu: {
    computed_url: string
    computed_title: string
  }[]
}
interface State {
  menus: Record<string, MenuModel[]>
  socials: Omit<GeneralSettings, 'id' | 'about' | 'site'> | null
}

export const useRootStore = defineStore('root', {
  state: (): State => ({
    menus: {},
    socials: null,
  }),

  actions: {
    async fetchInitialData () {
      try {
        await Promise.all([this.fetchMenus(), this.fetchSocials()])
      } catch (e) {
        console.error(e)
      }
    },

    async fetchMenus () {
      const { items } = await useApi().routing.getMenus()

      this.menus = items.reduce((acc: Record<string, MenuModel[]>, cur) => {
        acc[cur.slug] = cur.menu_items
          .filter(({ computed_url }) => computed_url)
          .map(({ computed_url, computed_title, sub_menu }) => {
            return {
              computed_url,
              computed_title,
              sub_menu: sub_menu
                .filter(({ computed_url }) => computed_url)
                .map(({ computed_url, computed_title }) => ({ computed_url, computed_title })),
            }
          })

        return acc
      }, {})
    },

    async fetchSocials () {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, about, site, ...data } = await useApi().routing.getSocials()
      this.socials = data
    },
  },
})
