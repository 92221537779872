import revive_payload_client_4sVQNw7RlN from "/home/node/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/node/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/node/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/node/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/node/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/node/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/node/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/node/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ksq7gplbDw from "/home/node/app/node_modules/@twicpics/components/nuxt3/plugin.js";
import vCalendar_81sNONyGsI from "/home/node/app/node_modules/@yescapa-dev/ysc-components/dist/runtime/plugins/vCalendar.mjs";
import i18n_yfWm7jX06p from "/home/node/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/home/node/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _02_ysc_api_js_3jXs9PEi9w from "/home/node/app/plugins/02.ysc-api-js.ts";
import _03_init_nhgAQMGiQu from "/home/node/app/plugins/03.init.ts";
import _04_sentry_client_tuXFV7LSkY from "/home/node/app/plugins/04.sentry.client.ts";
import _05_gtm_client_6DtW7C6cJm from "/home/node/app/plugins/05.gtm.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_ksq7gplbDw,
  vCalendar_81sNONyGsI,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  _02_ysc_api_js_3jXs9PEi9w,
  _03_init_nhgAQMGiQu,
  _04_sentry_client_tuXFV7LSkY,
  _05_gtm_client_6DtW7C6cJm
]