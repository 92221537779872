import validate from "/home/node/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _00_45header_45version_45global from "/home/node/app/middleware/00.header-version.global.ts";
import _01_45send_45events_45global from "/home/node/app/middleware/01.send-events.global.ts";
import manifest_45route_45rule from "/home/node/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _00_45header_45version_45global,
  _01_45send_45events_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "abort-on-special-backend-redirects": () => import("/home/node/app/middleware/abortOnSpecialBackendRedirects.ts"),
  "go-to-page-one": () => import("/home/node/app/middleware/goToPageOne.ts")
}