import type { MenusListReponse } from '~~/types/api/menu'
import type { GeneralSettings } from '~~/types/api/settings'

export function useRouting () {
  return {
    getMenus () {
      return useYscFetch<MenusListReponse>('/api/v1/menus/')
    },
    getSocials () {
      return useYscFetch<GeneralSettings>('/api/v1/general_settings/')
    },
  }
}
