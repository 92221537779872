import type { CollectionResponse } from '@/types/api/common'
import type { Tag } from '@/types/api/tag'

export function useTag () {
  return {
    getTagBySlug (slug: string) {
      return useYscFetch<CollectionResponse<Tag>>(`/api/v1/tags/?slug=${slug}`)
    },
  }
}
