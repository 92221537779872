import type { CollectionResponse } from '~~/types/api/common'
import type { Page, PagesCollectionFieldsAlwaysIncluded } from '~~/types/api/page'

export interface GetPagesQueryParams {
  descendant_of?: number
  fields?: string
  type?: Page['meta']['type']
  tag?: string
  id?: number
  order?: string
  page_size?: number
  page?: number
}

export interface GetPagesQueryParamsFixedType<T extends Page = Page> extends GetPagesQueryParams {
  descendant_of?: number
  fields?: string
  type?: T['meta']['type']
  tag?: string
  id?: number
  order?: string
  page_size?: number
  page?: number
  locale?: string
}

const COLLECTION_PAGE_FIELDS =
  'description,sub_title,tags(name),featured_image_rendered'
type PageCollectionResponseIncludedFields =
  | 'description'
  | 'sub_title'
  | 'tags'
  | 'featured_image_rendered'

export function usePage () {
  return {
    getDescendantsByPageId<T extends Page = Page> (pageId: number, params: Omit<GetPagesQueryParamsFixedType<T>, 'descendant_of' | 'fields'> = {}) {
      const allParams: GetPagesQueryParamsFixedType<T> = {
        ...params,
        descendant_of: pageId,
        fields: COLLECTION_PAGE_FIELDS,
      }
      type IncludedFields = Extract<PagesCollectionFieldsAlwaysIncluded | PageCollectionResponseIncludedFields, keyof T>
      type ReturnedPageType = Pick<T, IncludedFields>
      return useYscFetch<CollectionResponse<ReturnedPageType>>('/api/v1/pages/', { params: allParams })
    },

    getPagesById<T extends Page = Page, IncludedFields extends keyof T = never> (
      pageId: number,
      params: Omit<GetPagesQueryParamsFixedType<T>, 'id'>,
    ) {
      const allParams: GetPagesQueryParamsFixedType<T> = {
        ...params,
        id: pageId,
      }
      type ReturnedPage = Pick<T, Extract<PagesCollectionFieldsAlwaysIncluded | IncludedFields, keyof T>>
      return useYscFetch<CollectionResponse<ReturnedPage>>(
        '/api/v1/pages/',
        { params: allParams },
      )
    },

    getPagesBySlugTag<T extends Page = Page> (slug: string, params: Omit<GetPagesQueryParamsFixedType<T>, 'tag' | 'fields'> = {}) {
      const allParams: GetPagesQueryParamsFixedType<T> = {
        ...params,
        tag: slug,
        fields: COLLECTION_PAGE_FIELDS,
      }

      type ReturnedPage = Pick<T, Extract<PagesCollectionFieldsAlwaysIncluded | PageCollectionResponseIncludedFields, keyof T>>

      return useYscFetch<CollectionResponse<ReturnedPage>>('/api/v1/pages/', { params: allParams })
    },
  }
}
