import {
  init,
  setContext,
  setUser,
  setTag,
  addBreadcrumb,
  captureMessage,
  captureException,
  browserTracingIntegration,
} from '@sentry/vue'
import { sentryBeforeSend as beforeSend } from '~/utils/sentryBeforeSend'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const { vueApp } = nuxtApp
  const {
    public: {
      app: { version },
      sentry: { common: sentryCommonConfig, client: sentryClientConfig },
    },
  } = useRuntimeConfig()

  init({
    ...sentryCommonConfig,
    ...sentryClientConfig,
    app: vueApp,
    release: version,
    integrations: [
      browserTracingIntegration({
        router,
      }),
    ],
    tracePropagationTargets: ['localhost'],
    beforeSend,
  })

  return {
    provide: {
      sentry: {
        setContext,
        setUser,
        setTag,
        addBreadcrumb,
        captureMessage,
        captureException,
      },
    },
  }
})
