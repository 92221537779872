import { YscApiConsumer } from '@yescapa-dev/ysc-api-js/modern'
import type { $Fetch } from 'ofetch'

// This plugin needs to be registered AFTER i18n plugin
export default defineNuxtPlugin(() => {
  const { $i18n } = useNuxtApp()
  const { public: { api: { rental: { url, key } } } } = useRuntimeConfig()

  const modernInstance = $fetch.create({
    onRequest({ options }) {
      options.headers = {
        ...options.headers,
        'X-API-KEY': key,
        'Accept-Language': $i18n.locale.value
      }
    }
  })

  const apiConsumer = new YscApiConsumer(modernInstance as $Fetch, url)

  return {
    provide: {
      apiConsumer
    },
  }
})
