<script setup lang="ts">
interface Props {
  onClickCallback?: () => void
}

const props = defineProps<Props>()

const state = reactive({
  showNav: false,
})
function onClick() {
  state.showNav = !state.showNav
  if (props.onClickCallback)
    props.onClickCallback()
}
function close() {
  state.showNav = false
}
</script>

<template>
  <header
    :class="{ shadow: state.showNav }"
    class="absolute top-0 left-0 right-0 z-10 border-b bg-white"
    @mouseleave="close"
  >
    <div class="container">
      <div class="flex justify-between lg:space-x-16 xl:space-x-28">
        <NuxtLink
          to="/"
          class="flex-none py-5 lg:py-9"
        >
          <img
            src="~/assets/img/yescapa-blog.svg"
            class="h-7 w-auto lg:h-8"
            alt="Yescapa"
            width="144"
            height="50"
          >
        </NuxtLink>

        <nav
          class="hidden lg:block lg:grow"
          @mouseenter="state.showNav = true"
        >
          <AppNav :show-nav="state.showNav" />
        </nav>

        <div class="flex lg:hidden">
          <button
            type="button"
            class="p-3 text-black -mr-3 self-center"
            :title="$t('commons.menu')"
            @click="onClick()"
          >
            <YscIconsBurger 
              v-if="!state.showNav" 
              class="h-6 w-6" 
            />
            <YscIconsDelete 
              v-else 
              class="h-6 w-6" 
            />
          </button>
        </div>
      </div>
      <nav
        v-show="state.showNav"
        class="lg:hidden"
      >
        <AppNav
          :show-nav="state.showNav"
          @close-nav="close"
        />
      </nav>
    </div>
  </header>
</template>
