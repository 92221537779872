import { default as _91topCategory_938qKVK04I02Meta } from "/home/node/app/pages/[topCategory].vue?macro=true";
import { default as indexca63Ho8mnZMeta } from "/home/node/app/pages/index.vue?macro=true";
import { default as frontrj5B0bF64EMeta } from "/home/node/app/pages/ping/front.vue?macro=true";
import { default as _91slug_93vUyApt3QJmMeta } from "/home/node/app/pages/tags/[slug].vue?macro=true";
import { default as postIdE4BkXjbEU5Meta } from "/home/node/app/extra-pages/postId.vue?macro=true";
import { default as categoryListB2JA9Dd6uWMeta } from "/home/node/app/extra-pages/categoryList.vue?macro=true";
export default [
  {
    name: "topCategory",
    path: "/:topCategory()",
    component: () => import("/home/node/app/pages/[topCategory].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/node/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "ping-front",
    path: "/ping/front",
    meta: frontrj5B0bF64EMeta || {},
    component: () => import("/home/node/app/pages/ping/front.vue").then(m => m.default || m)
  },
  {
    name: "tags-slug",
    path: "/tags/:slug()",
    meta: _91slug_93vUyApt3QJmMeta || {},
    component: () => import("/home/node/app/pages/tags/[slug].vue").then(m => m.default || m)
  },
  {
    name: "topCategoryPost",
    path: "/:topCategory/p/:id",
    component: () => import("/home/node/app/extra-pages/postId.vue").then(m => m.default || m)
  },
  {
    name: "secondCategoryPost",
    path: "/:topCategory/:secondCategory/p/:id",
    component: () => import("/home/node/app/extra-pages/postId.vue").then(m => m.default || m)
  },
  {
    name: "thirdCategoryPost",
    path: "/:topCategory/:secondCategory/:thirdCategory/p/:id",
    component: () => import("/home/node/app/extra-pages/postId.vue").then(m => m.default || m)
  },
  {
    name: "secondCategoryList",
    path: "/:topCategory/:secondCategory",
    meta: categoryListB2JA9Dd6uWMeta || {},
    component: () => import("/home/node/app/extra-pages/categoryList.vue").then(m => m.default || m)
  },
  {
    name: "thirdCategoryList",
    path: "/:topCategory/:secondCategory/:thirdCategory",
    component: () => import("/home/node/app/extra-pages/categoryList.vue").then(m => m.default || m)
  }
]