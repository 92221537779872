<script setup lang="ts">
const state = reactive<{ loading: boolean; error: boolean | null | any; message: string | null }>({
  loading: false,
  error: false,
  message: null,
})

const { t } = useI18n()

async function onNewsletterSubmit ({ email }: { email: string }) {
  state.loading = true
  state.error = null
  const { error } = await useFetch('/internals/newsletter', { method: 'post', body: { email } })
  state.loading = false
  if (error.value) {
    state.error = error
    if (error.value.data.statusCode !== 400) {
      const { $sentry } = useNuxtApp()
      $sentry.captureException('BlogNewsletterError')
      state.message = t('commons.error')
      return
    }
    type errorCode = 'email_already_exists' | 'duplicate_parameter' | 'email_is_invalid'
    const { code }: { code: errorCode } = error.value.data.data
    const message = {
      email_already_exists: t('data.newsletter.error.email_already_exists'),
      duplicate_parameter: t('data.newsletter.error.email_already_exists'),
      email_is_invalid: t('data.newsletter.error.email_is_invalid'),
    }
    state.message = message[code]
    return
  }
  state.message = t('data.newsletter.success')
}
</script>

<template>
  <div>
    <YscNewsletter
      class="flex-wrap"
      :loading="state.loading"
      @submit="onNewsletterSubmit"
    />
    <p
      v-if="state.message"
      class="mt-2 font-semibold"
      :class="{
        'text-green-500': !state.error,
        'text-red-500': state.error,
      }"
    >
      {{ state.message }}
    </p>
  </div>
</template>
