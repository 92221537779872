export default defineNuxtPlugin(() => {
  const {
    public: {
      gtag: { id, enabled },
    },
  } = useRuntimeConfig()

  onNuxtReady(() => {
    if (!import.meta.client || !id) {
      return
    }
    const doc: Document = document
    const script = doc.createElement('script')

    script.async = true
    script.defer = false

    const source = new URL('https://www.googletagmanager.com/gtm.js')
    source.searchParams.set('id', id)
    script.src = source.href

    const parentElement = doc.body

    if (typeof parentElement?.appendChild !== 'function') {
      throw new TypeError('parentElement must be a DOM element')
    }

    parentElement.appendChild(script)
  })

  window.dataLayer ??= []

  // keep args declaration to let typescript know it's possible to pass arguments.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function gtm (...args: unknown[]) {
    if (!enabled) {
      return
    }

    // destructured args aren't working, keep arguments
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments)
  }

  gtm('event', 'gtm.js', {
    'gtm.start': new Date().getTime(),
  })

  gtm('config', id, {
    send_page_view: true,
  })

  return { provide: { gtm } }
})
