<script setup lang="ts">
defineProps({
  error: {
    type: Object,
    default: () => ({}),
  },
})

const goHome = () => clearError({ redirect: '/' })
</script>

<template>
  <div class="flex min-h-screen flex-col pt-14 sm:pt-16 lg:pt-24">
    <AppHeader />
    <section class="bg-peacock-50 py-12 lg:py-20">
      <div class="space-y-12 md:space-y-20">
        <div class="container flex flex-col gap-12 lg:flex-row">
          <img
            src="~/assets/img/t6-beach.svg"
            class="mx-auto max-w-sm"
          >
          <div>
            <div class="prose">
              <h1>{{ $t('not_found.title') }}</h1>
              <p>
                {{ $t('not_found.dont_worry') }}
              </p>
            </div>
            <div class="mt-6">
              <button
                class="btn btn-primary"
                @click="goHome"
              >
                {{ $t('links.homepage') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <AppFooter />
  </div>
</template>
